import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

//import images
//import about from '../images/about-img.jpg';
import { withRouter } from "react-router-dom";
import { fetchSrp, postName, postEmail, save } from "../actions/postActions";
import { connect } from "react-redux";

class About extends Component {

    createUIItems() {
        return this.props.aboutData.map((el, i) => {
          return (
            <>
            {el.value && el.type=="ITEM"? <Col key={i} lg={4}>
            <div className={this.props.isPrimary ? "about-box text-center p-3" : "about-box about-light text-center p-3"}>
                <div className="about-icon mb-4">
                    <i className={"mdi " + el.icon}></i>
                </div>
                <h4 className="fw-light"><Link to="#" className={this.props.isPrimary ? "text-white" : "text-dark"}>{el.value}</Link></h4>
                <p className={this.props.isPrimary ? "text-white-50 f-14" : "text-muted f-14"}>

                </p>
            </div>
        </Col>: ""}
        </>                   
          );
        });
      }

      createUIContent() {
        return this.props.aboutData.map((el, i) => {
          return (
            <>
            {el.value && el.type=="CONTENT"? <Col md={6}>
                                <div className="about-desc">
                                    <p className={this.props.isPrimary ? "text-white-50 f-15 text-justify" : "text-muted f-15 text-justify"}>
                                        {el.value}
                                   </p>
                                    {/* <div className="about-by">
                                        <p className="fw-light mb-0"><Link to="#" className={this.props.isPrimary ? "text-white" : "text-dark"}><i
                                            className="mdi mdi-circle-medium text-custom me-2"></i>
                                            Learn More <span className="text-custom"> About Us</span></Link></p>
                                    </div> */}
                                </div>
                            </Col>: ""}
        </>                   
          );
        });
      }
      
      createUIHeading() {
        return this.props.aboutData.map((el, i) => {
          return (
            <div key={i}>
             
             {el.value && el.type=="HEADING"? <div>{el.value}</div>: ""}
             
            </div>
          );
        });
      }
    render() {
        const imageAboutPage = this.props.imagesData.find(function (imgObj) {
            return imgObj.image_name=='aboutus';
        });
        const imageAboutBg = this.props.imagesData.find(function (imgObj) {
            return imgObj.image_name=='about_bg';
        });
        const imageAboutLightBg = this.props.imagesData.find(function (imgObj) {
            return imgObj.image_name=='about_bg_light';
        });
        return (
            <React.Fragment>
                <section style={{backgroundImage: "url(" + imageAboutLightBg.image_location + ")"}} className={this.props.isPrimary ? "section bg-about" : "section bg-about bg-light-about bg-light"} id="about">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="title-heading mb-5">
                                    <h3 className={this.props.isPrimary ? "text-white mb-1 fw-light text-uppercase" : "text-dark mb-1 fw-light text-uppercase"}>{this.createUIHeading()} </h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                        {this.createUIItems()} 
                        </Row>

                        {/* <Row>
                            
                            <Col lg={4}>
                                <div className={this.props.isPrimary ? "about-box text-center p-3" : "about-box about-light text-center p-3"}>
                                    <div className="about-icon mb-4">
                                        <i className="mdi mdi-projector-screen"></i>
                                    </div>
                                    <h4 className="fw-light"><Link to="#" className={this.props.isPrimary ? "text-white" : "text-dark"}>ERP Solutions</Link></h4>
                                    <p className={this.props.isPrimary ? "text-white-50 f-14" : "text-muted f-14"}>

                                    </p>
                                </div>
                            </Col>

                            <Col lg={4}>
                                <div className={this.props.isPrimary ? "about-box text-center p-3" : "about-box about-light text-center p-3"}>
                                    <div className="about-icon mb-4">
                                        <i className="mdi mdi-nature"></i>
                                    </div>
                                    <h4 className="fw-light"><Link to="#" className={this.props.isPrimary ? "text-white" : "text-dark"}>Customer Relationship Management</Link></h4>
                                    <p className={this.props.isPrimary ? "text-white-50 f-14" : "text-muted f-14"}>

                                    </p>
                                </div>
                            </Col>

                            <Col lg={4}>
                                <div className={this.props.isPrimary ? "about-box text-center p-3" : "about-box about-light text-center p-3"}>
                                    <div className="about-icon mb-4">
                                        <i className="mdi mdi-nature"></i>
                                    </div>
                                    <h4 className="fw-light"><Link to="#" className={this.props.isPrimary ? "text-white" : "text-dark"}>Supply Chain Management</Link></h4>
                                    <p className={this.props.isPrimary ? "text-white-50 f-14" : "text-muted f-14"}>

                                    </p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className={this.props.isPrimary ? "about-box text-center p-3" : "about-box about-light text-center p-3"}>
                                    <div className="about-icon mb-4">
                                        <i className="mdi mdi-lightbulb"></i>
                                    </div>
                                    <h4 className="fw-light"><Link to="#" className={this.props.isPrimary ? "text-white" : "text-dark"}>Electronic Commerce</Link></h4>
                                    <p className={this.props.isPrimary ? "text-white-50 f-14" : "text-muted f-14"}>
                                    </p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className={this.props.isPrimary ? "about-box text-center p-3" : "about-box about-light text-center p-3"}>
                                    <div className="about-icon mb-4">
                                        <i className="mdi mdi-lightbulb"></i>
                                    </div>
                                    <h4 className="fw-light"><Link to="#" className={this.props.isPrimary ? "text-white" : "text-dark"}>Systems Integration</Link></h4>
                                    <p className={this.props.isPrimary ? "text-white-50 f-14" : "text-muted f-14"}>
                                    </p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className={this.props.isPrimary ? "about-box text-center p-3" : "about-box about-light text-center p-3"}>
                                    <div className="about-icon mb-4">
                                        <i className="mdi mdi-lightbulb"></i>
                                    </div>
                                    <h4 className="fw-light"><Link to="#" className={this.props.isPrimary ? "text-white" : "text-dark"}>Consulting</Link></h4>
                                    <p className={this.props.isPrimary ? "text-white-50 f-14" : "text-muted f-14"}>
                                    </p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className={this.props.isPrimary ? "about-box text-center p-3" : "about-box about-light text-center p-3"}>
                                    <div className="about-icon mb-4">
                                        <i className="mdi mdi-lightbulb"></i>
                                    </div>
                                    <h4 className="fw-light"><Link to="#" className={this.props.isPrimary ? "text-white" : "text-dark"}>IT Outsourcing & Off-Shore Software Development Services</Link></h4>
                                    <p className={this.props.isPrimary ? "text-white-50 f-14" : "text-muted f-14"}>
                                    </p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className={this.props.isPrimary ? "about-box text-center p-3" : "about-box about-light text-center p-3"}>
                                    <div className="about-icon mb-4">
                                        <i className="mdi mdi-lightbulb"></i>
                                    </div>
                                    <h4 className="fw-light"><Link to="#" className={this.props.isPrimary ? "text-white" : "text-dark"}>Web Content Management system</Link></h4>
                                    <p className={this.props.isPrimary ? "text-white-50 f-14" : "text-muted f-14"}>
                                    </p>
                                </div>
                            </Col>
                        </Row> */}


                        <Row className="align-items-center mt-5">
                            {this.createUIContent()}
                            {/* <Col md={6}>
                                <div className="about-desc">
                                    <p className={this.props.isPrimary ? "text-white-50 f-15 text-justify" : "text-muted f-15 text-justify"}>
                                   ABOUT The first step to achieving great results is determining where you want to go. The next is finding the right partner to help get you there. Active Squares Inc combines knowledge with focus to deliver solutions to maximize business goals. We envision, plan and execute methodologies to improve and simplify business processes while enhancing reporting capabilities. We are able to combine people skills with what the best of today’s technology has to offer from a creative point of view. We take pride in having an unusual talent for turning challenges and ideas into solutions that can yield profitable results.
Our consulting practice focuses on delivering fully capable project teams to provide knowledgeable consultants who work with the client to understand their business objectives, assist in design and implementation, and aggressively push into a transition phase to reduce client dependency on our consulting services. Our goal is to build solutions which allow our clients to be self-sufficient, eliminating proprietary knowledge lock-in and promoting open knowledge sharing.
                                   </p>
                                    <div className="about-by">
                                        <p className="fw-light mb-0"><Link to="#" className={this.props.isPrimary ? "text-white" : "text-dark"}><i
                                            className="mdi mdi-circle-medium text-custom me-2"></i>
                                            Learn More <span className="text-custom"> About Us</span></Link></p>
                                    </div>
                                </div>
                            </Col> */}

                            <Col md={6}>
                                <div className={this.props.isPrimary ? "about-img position-relative p-4" : "about-img light-img position-relative p-4"}>
                                    <img src={imageAboutPage.image_location} alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>

                        </Row>

                    </Container>
                </section>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    item: state.postsReduserName.item,
    name: state.postsReduserName.name,
    email: state.postsReduserName.email,
    nextScreen: state.postsReduserName.nextScreen,
    aboutData: state.postsReduserName.aboutData,
    imagesData: state.postsReduserName.imagesData
  });
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      fetchSrp: (payload) => {
        dispatch(fetchSrp(payload, ownProps));
      },
      postName: (payload) => {
        dispatch(postName(payload, ownProps));
      },
      postEmail: (payload) => {
        dispatch(postEmail(payload, ownProps));
      },
      save: (payload) => {
        dispatch(save(payload, ownProps));
      },
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(About));