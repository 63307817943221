import React, { Component } from "react";
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from "reactstrap";
// Import Logo
import logodark from "../../images/zooki.png";
import logolight from "../../images/zooki.png";
;
import { withRouter } from "react-router-dom";
import { fetchSrp, postName, postEmail, save } from "../../actions/postActions";
import { connect } from "react-redux";

//import icon
import FeatherIcon from "feather-icons-react";

import ScrollspyNav from "./Scrollspy";

class NavbarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
    };
  }

  toggle = () => {
    //alert("Toggle is clicked>>>>>");
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    const imageLogo= this.props.imagesData.find(function (imgObj) {
      return imgObj.image_name=='logo';
  });
    var targetId = this.props.navItems.map((item) => {
      return item.idnm;
    });
    return (
      <React.Fragment>
        {/* Navbar Start */}
        <nav
         className={"navbar navbar-expand-lg fixed-top sticky " + this.props.navClass}
        >
          <Container>
            {/* LOGO */}
            <NavbarBrand className="logo" href="/">
              {this.props.imglight === true ? (
                <img src={imageLogo.image_location} alt="Test" width={''+imageLogo.image_width} height={''+imageLogo.image_height} />
                //ACTIVE SQUARES {this.props.logoData} 
                
                // <p className="text-white-logo-100 mt-4 f-15 mb-0 text-justify">
                // ACTIVE {imageLogo.image_location}         
                // </p>
              ) : (
                <img src={imageLogo.image_location} alt="Test" width={''+imageLogo.image_width} height={''+imageLogo.image_height} />
              //   <p className="text-white-logo-100 mt-4 f-15 mb-0 text-justify">
              //  ACTIVE 1{imageLogo.image_location}        
              //   </p>
              )}
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle}>
              <i>
                <FeatherIcon icon="menu" />
              </i>
            </NavbarToggler>
            <Collapse
              id="navbarCollapse"
              isOpen={this.state.isOpenMenu}
              className=" navbar-collapse"
            >
              <ScrollspyNav
                scrollTargetIds={targetId}
                scrollDuration="800"
                headerBackground="true"
                activeNavClass="active"
                className="navbar-collapse"
              >
                <Nav navbar className="ms-auto navbar-center" id="mySidenav">
                  {this.props.navItems.map((item, key) => (
                    <NavItem
                      key={key}
                      className={item.navheading === "Home" ? "active" : ""}
                    >
                      <NavLink
                        className={item.navheading === "Home" ? "active" : ""}
                        href={"#" + item.idnm}
                      >
                        {item.navheading}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </ScrollspyNav>
            </Collapse>
          </Container>
        </nav>
      </React.Fragment>
    );
  }
}
// }
//export default NavbarPage;
const mapStateToProps = (state) => ({
  item: state.postsReduserName.item,
  name: state.postsReduserName.name,
  email: state.postsReduserName.email,
  nextScreen: state.postsReduserName.nextScreen,
  menuData: state.postsReduserName.menuData,
  logoData: state.postsReduserName.logoData,
  imagesData: state.postsReduserName.imagesData,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchSrp: (payload) => {
      dispatch(fetchSrp(payload, ownProps));
    },
    postName: (payload) => {
      dispatch(postName(payload, ownProps));
    },
    postEmail: (payload) => {
      dispatch(postEmail(payload, ownProps));
    },
    save: (payload) => {
      dispatch(save(payload, ownProps));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavbarPage));