import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Input, Row } from 'reactstrap'

import { postName, postEmail, postSub, postMessage, save } from "../actions/postActions2";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";


class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
          startDate: new Date(),
          endDate: new Date(),
          showLoading: false,
          disableSubmit: false,
          showOverlay: true,
          showPopup: false,
          errorMessage: "",
        };

        this.onFormSubmit = this.onFormSubmit.bind(this);
      }

    //   componentDidUpdate(prevProps, prevState) {

    //     console.log("componentDidUpdate >>>>>>>>>>> after save");
    //     console.log("next screen .....");
    //     console.log(this.props.nextScreen);
    //   }
    attachPropFormNode(node) {
        this._form = ReactDOM.findDOMNode(node);
      }
      
      onFormSubmit = (event) => {
        event.preventDefault();
        this.props.save({ action: "save" });    
      };

      handleCheck() {
        return true;
      }

      handleSubmit(event) {
        event.preventDefault();
        this.props.save({name:this.props.name, email: this.props.email, sub: this.props.sub, message: this.props.message});
      }

      onChangeName = (e) => {
        this.props.postName({name:e.currentTarget.value});
      };

      onKeyDownName = (e) => {
        this.props.postName({name:e.currentTarget.value});
      };

      onChangeEmail = (e) => {
        this.props.postEmail({email:e.currentTarget.value});
      };

      onKeyDownEmail = (e) => {
        this.props.postEmail({email:e.currentTarget.value});
      };

      onChangeSub = (e) => {
        this.props.postSub({sub:e.currentTarget.value});
      };

      onKeyDownSub = (e) => {
        this.props.postSub({sub:e.currentTarget.value});
      };
      onChangeMessage = (e) => {
        this.props.postMessage({message:e.currentTarget.value});
      };

      onKeyDownMessage = (e) => {
        this.props.posMessage({message:e.currentTarget.value});
      };

    render() {
        const styles = {
            backgroundColor: "",
            fontSize: "20px",
            color: "#088132",
            padding: 0
         }
        const {
            onChangeName,
            onKeyDownName,
            onChangeEmail,
            onKeyDownEmail,
            onChangeSub,
            onKeyDownSub,
            onChangeMessage,
            onKeyDownMessage
          } = this;
        return (
            <React.Fragment>
                <section className="section bg-light" id="contact">
                <form
              ref={this.attachPropFormNode.bind(this)}
              method="POST"
              onSubmit={this.onFormSubmit}
            >
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 fw-light text-uppercase">Get in touch</h3>
                                    {/* {this.props.name}
                                    {this.props.email}{this.props.sub}{this.props.message}  */}
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                                <div style={styles}>
                                    {this.props.nextScreen}
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <div className="contact-box p-5">
                                    <Row>
                                        <Col lg={8} md={6}>
                                            <div className="custom-form p-3">
                                                <form name="myForm">
                                                    <p id="error-msg"></p>
                                                    <div id="simple-msg"></div>
                                                    <Row>
                                                        <Col lg={6}>
                                                            <div className="mb-3 app-label">
                                                                <Input name="name" id="name" type="text" className="form-control"
                                                                    placeholder="Name" value={this.props.name}
                                                                    onChange={onChangeName}
                                                                    onKeyDown={onKeyDownName}
                                                                    />
                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="mb-3 app-label">
                                                                <Input name="email" id="email" type="email" className="form-control"
                                                                    placeholder="Email" 
                                                                    value={this.props.email}
                                                                    onChange={onChangeEmail}
                                                                    onKeyDown={onKeyDownEmail}
                                                                    />
                                                            </div>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <div className="mb-3 app-label">
                                                                <Input type="text" className="form-control" id="subject"
                                                                    placeholder="Subject" 
                                                                    value={this.props.sub}
                                                                    onChange={onChangeSub}
                                                                    onKeyDown={onKeyDownSub}
                                                                    />
                                                            </div>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <div className="mb-3 app-label">
                                                                <textarea name="comments" id="comments" rows="5"
                                                                    className="form-control" placeholder="Message"
                                                                    value={this.props.message}
                                                                    onChange={onChangeMessage}
                                                                    onKeyDown={onChangeMessage}
                                                                    ></textarea>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <div className="col-sm-12">
                                                            <input type="submit" id="submit" name="send"
                                                                className="submitBnt btn btn-custom" value="Send Message" 
                                                                onClick={this.handleSubmit.bind(this)}/>

                                                             {/* <button
                                                                className="submitBnt btn btn-custom" value="Send Message"
                                                                // disabled={this.state.disableSubmit}
                                                                type="submit"
                                                                onClick={this.handleSubmit.bind(this)}
                                                            ></button> */}
                                                       </div>

                                                    </Row>
                                                </form>
                                            </div>
                                        </Col>

                                        {/* orgItem.organization_name= orgRow.organization_name;
              orgItem.organization_type= orgRow.organization_type;
              orgItem.organization_phone= orgRow.organization_phone;
              orgItem.organization_address1= orgRow.organization_address1;
              orgItem.organization_address2= orgRow.organization_address2;
              orgItem.organization_city= orgRow.organization_city;
              orgItem.organization_state= orgRow.organization_state;
              orgItem.organization_country= orgRow.organization_country;
              orgItem.organization_postalcode= orgRow.organization_postalcode; */}

                                        <Col lg={4} md={6}>
                                            <div className="contact-cantent p-3">
                                                <div className="contact-details">
                                                    <div className="float-start contact-icon me-3 mt-2">
                                                        <i className="mdi mdi-headphones text-muted h5"></i>
                                                    </div>
                                                    <div className="app-contact-desc text-muted pt-1">
                                                        <p className="mb-0 info-title f-13">Call :</p>
                                                        <p className="mb-0 f-13">{this.props.orgData.organization_phone}</p>
                                                    </div>
                                                </div>

                                                <div className="contact-details mt-2">
                                                    <div className="float-start contact-icon me-3 mt-2">
                                                        <i className="mdi mdi-email-outline text-muted h5"></i>
                                                    </div>
                                                    <div className="app-contact-desc text-muted pt-1">
                                                        <p className="mb-0 info-title f-13">Email :</p>
                                                        <p className="mb-0 f-13"><Link to="" className="text-muted">{this.props.orgData.organization_email}</Link>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="contact-details mt-2">
                                                    <div className="float-start contact-icon me-3 mt-2">
                                                        <i className="mdi mdi-map-marker text-muted h5"></i>
                                                    </div>
                                                    <div className="app-contact-desc text-muted pt-1">
                                                        <p className="mb-0 info-title f-13">Location :</p>
                                                        <p className="mb-0 f-13"><Link to="" className="text-muted">{this.props.orgData.organization_name}</Link></p>
                                                        <p className="mb-0 f-13">{this.props.orgData.organization_address1}</p>
                                                        <p className="mb-0 f-13">{this.props.orgData.organization_address2}</p>
                                                   </div>
                                                </div>

                                                {/* <div className="follow mt-4">
                                                    <h4 className="text-dark mb-3">Follow</h4>
                                                    <ul className="follow-icon list-inline mt-32 mb-0">
                                                        <li className="list-inline-item f-15"><Link to="#"
                                                            className="social-icon text-muted me-1"><i className="mdi mdi-facebook"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item f-15"><Link to="#"
                                                            className="social-icon text-muted me-1"><i className="mdi mdi-twitter"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item f-15"><Link to="#"
                                                            className="social-icon text-muted me-1"><i
                                                                className="mdi mdi-google-plus"></i></Link></li>
                                                        <li className="list-inline-item f-15"><Link to="#"
                                                            className="social-icon text-muted me-1"><i className="mdi mdi-linkedin"></i></Link>
                                                        </li>
                                                        <li className="list-inline-item f-15"><Link to="#"
                                                            className="social-icon text-muted me-1"><i className="mdi mdi-whatsapp"></i></Link>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    </form>
                </section>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    item: state.postsReduserName.item,
    name: state.postsReduserName.name,
    email: state.postsReduserName.email,
    sub: state.postsReduserName.sub,
    message: state.postsReduserName.message,
    nextScreen: state.postsReduserName.nextScreen,
    orgData: state.postsReduserName.orgData
  });
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      postName: (payload) => {
        dispatch(postName(payload, ownProps));
      },
      postEmail: (payload) => {
        dispatch(postEmail(payload, ownProps));
      },
      postSub: (payload) => {
        dispatch(postSub(payload, ownProps));
      },
      postMessage: (payload) => {
        dispatch(postMessage(payload, ownProps));
      },
      save: (payload) => {
        dispatch(save(payload, ownProps));
      },
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact));
  