import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from "react-router-dom";
import { fetchSrp, postName, postEmail, save } from "../actions/postActions";
import { connect } from "react-redux";

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // servicesData: [
            //     {
            //         id: 1,
            //         icon: 'mdi-database',
            //         title: 'Thought leadership and design services',
            //         description: 'Successful businesses require innovative ideas, sound business strategies, actionable business plans, focused and effective execution of large and small initiatives and alignment across all key stakeholders. Our Strategy and Change management services combine strategy, organizational change management and program and project management competencies to help you build, grow and sustain a successful business.'
            //     },
            //     {
            //         id: 2,
            //         icon: 'mdi-palette',
            //         title: 'We innovatively work to',
            //         description: 'Identify root cause of problems or inefficiencies and provide ideas to achieve competitive advantage by using technology more effectively, ERP Solutions -> Leverage knowledge and expertise to accelerate solution delivery, Integrate best practices, balanced scorecard, and Six Sigma techniques, Address cultural aspects of change by integrating people with process, Provide strong program and project management expertise to deliver solution'
                   
                    
            //     },
            //     {
            //         id: 3,
            //         icon: 'mdi-finance',
            //         title: 'Enterprise applications services',
            //         description: 'Since, enterprise applications are building blocks of any business, Active Squares focuses on planning, deploying and supporting these applications to dramatically reduce the costs and streamline processes. The Enterprise Applications Solutions practice delivers package-based solutions to help clients optimize operations throughout their value chain. Our professionals bring functional and technical expertise to the management of human resources, financials, customer relationships and supply chains. We focus our implementation services for leading software applications including PeopleSoft/Oracle'

            //     },
            //     {
            //         id: 4,
            //         icon: 'mdi-yin-yang',
            //         title: 'Within enterprise applications, our services are focused on',
            //         description: 'Customer Relationship Management,Financials,Human Resources,Supply Chain Management, Retail Billing & Transactional Management System'
                    
            //     },
            //     {
            //         id: 5,
            //         icon: 'mdi-apple-keyboard-command',
            //         title: 'Business intelligence solutions',
            //         description: 'The world of constant change increases the demand for accurate information for analytical and reporting purposes. Our knowledgeable data warehousing & business analytics consultants help companies implement enterprise performance management solutions to facilitate effective decision-making at all levels of the organization. We focus on needs of various user groups in the organization and provide basis for advanced enterprise analysis. We deliver packaged analytics, enterprise reporting and custom analytics.'
            //     },
            //     {
            //         id: 6,
            //         icon: 'mdi-hexagon-multiple',
            //         title: 'We offer creative solutions for Enterprise Performance Management, Data Architecture and Modeling and Data Extraction & Transformation',
            //         description: 'Design and develop a business intelligence infrastructure, as a repository for critical business data that can serve as a system of record for data analysis. Financials -> Provide analytical end user tools that can produce and distribute ad-hoc and routine reports. Deliver accurate information in a timely manner to better make decisions to execute strategies, track and monitor progress, and perform modeling for virtually any aspect of the business. Identify market trends and business opportunities for budgeting, forecasting and planning purposes.'
                    
            //     },
            //     {
            //         id: 7,
            //         icon: 'mdi-hexagon-multiple',
            //         title: 'Compliance and Assurance:',
            //         description: 'The arrival of new compliance pressures such as Sarbanes Oxley and others, all need to be addressed and demonstrated to auditors. The focus has been to provide effective solutions to reduce exposure to internal and external risk in a complex environment. We focus on meeting the demands of the regulatory compliance utilizing packaged applications or help build custom applications.'
                      
            //     },
            //     {
            //         id: 8,
            //         icon: 'mdi-hexagon-multiple',
            //         title: 'Custom application development and Integration:',
            //         description: 'Enterprise-wide or smaller scale, client/server or n-tier, Internet or Extranet, we pride ourselves in offering our clients some of the most experienced developers available. We have deep experience using middleware as well as using Microsoft architectures (including .NET) to build solutions that are scalable, easy to support and cost effective. We creatively develop solutions to help organizations achieve unique competitive advantage through new systems and solutions. Our innovative solutions and focused services help connect and streamline business processes, systems and information. Our collaborative approach enables effective integration of business processes to help organizations access, manage and exploit data to make more informed business decisions.'
                    
            //     }
            // ]
        }
    }
    render() {
        const imageAboutPage = this.props.imagesData.find(function (imgObj) {
            return imgObj.image_name=='aboutus';
        });
        const imageAboutBg = this.props.imagesData.find(function (imgObj) {
            return imgObj.image_name=='about_bg';
        });
        const imageAboutLightBg = this.props.imagesData.find(function (imgObj) {
            return imgObj.image_name=='about_bg_light';
        });
        return (
            <React.Fragment>
                {/* <section className="section" id="services"> */}
                <section style={{backgroundImage: "url(" + imageAboutLightBg.image_location + ")"}} className={this.props.isPrimary ? "section bg-about" : "section bg-about bg-light-about bg-light"} id="services">

                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 fw-light text-uppercase">Our Services</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            {this.props.servicesData.map((services, key) => (
                                <Col lg={4} md={6} key={key}>
                                    <div className="service-box rounded mt-4 p-4">
                                        <div className="service-icon mb-3">
                                            <i className={"mdi " + services.icon}></i>
                                        </div>
                                        <div className="services-desc">
                                            <div className="service-title mb-2 position-relative">
                                                <h5 className="fw-normal mb-3"><Link to="#" className="text-dark">{services.title}</Link></h5>
                                            </div>
                                            <p className="text-muted mb-3 f-14 text-justify">{services.description}</p>
                                            {/* <p className="mb-0 text-uppercase f-13"><Link to="#" className="text-primary">learn more<i
                                                className="mdi mdi-arrow-right ms-2"></i></Link></p> */}
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
//export default Layout_1;
const mapStateToProps = (state) => ({
    item: state.postsReduserName.item,
    name: state.postsReduserName.name,
    email: state.postsReduserName.email,
    nextScreen: state.postsReduserName.nextScreen,
    servicesData: state.postsReduserName.servicesData,
    imagesData: state.postsReduserName.imagesData

  });
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      fetchSrp: (payload) => {
        dispatch(fetchSrp(payload, ownProps));
      },
      postName: (payload) => {
        dispatch(postName(payload, ownProps));
      },
      postEmail: (payload) => {
        dispatch(postEmail(payload, ownProps));
      },
      save: (payload) => {
        dispatch(save(payload, ownProps));
      },
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Services));