import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

//import images
// import features from '../images/features-img/img-1.png';
// import features2 from '../images/features-img/img-2.png';
// import features3 from '../images/features-img/img-3.png';
import { withRouter } from "react-router-dom";
import { fetchSrp, postName, postEmail, save } from "../actions/postActions";
import { connect } from "react-redux";

class Features extends Component {
    createUIItems() {
        return this.props.jobData.map((el, i) => {
          return (
            <>
            {el.jp_type && el.jp_type=="ITEM"? 
                                        <Col lg={6}>
                                        <div className="features-content">
                                            <h4 className="fw-normal text-dark mb-3 mt-4">{el.jp_title}</h4>
                                            <p className="text-muted f-14">{el.jp_subject}</p>
                                            <h4 className="fw-normal text-dark mb-3 mt-4">{el.jp_duties_title}</h4>
                                            <p className="text-muted f-14">{el.jp_duties_desc}</p>
        <h4 className="fw-normal text-dark mb-3 mt-4">{el.jp_skills_title}</h4>
        <p className="text-muted f-14">{el.jp_skills_desc}</p>
        <h6 className="fw-normal text-dark mb-3 mt-4">{el.jp_contact_details}</h6>                               
                                        </div>
                                    </Col>
        : ""}
        </>                   
          );
        });
      }
    render() {
      const imageJobsPage = this.props.imagesData.find(function (imgObj) {
        return imgObj.image_name=='jobs';
      });
      const imageClientBg = this.props.imagesData.find(function (imgObj) {
      return imgObj.image_name=='client_bg';
      });
      const imageAboutPage = this.props.imagesData.find(function (imgObj) {
      return imgObj.image_name=='aboutus';
     });
     const imageAboutBg = this.props.imagesData.find(function (imgObj) {
     return imgObj.image_name=='about_bg';
     });
      const imageAboutLightBg = this.props.imagesData.find(function (imgObj) {
    return imgObj.image_name=='about_bg_light';
     });
        return (
            <React.Fragment>
             <section style={{backgroundImage: "url(" + imageAboutLightBg.image_location + ")"}} className={this.props.isPrimary ? "section bg-features" : "section bg-features bg-light-features bg-light"} id="features">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 fw-light text-uppercase">LATEST OPENINGS</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            {this.createUIItems()}
                            
                            <Col lg={6}>
                                <div style={{backgroundImage: "url(" + imageClientBg.image_location + ")"}} className="features-img mt-32">
                                    <img src={imageJobsPage.image_location} alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    item: state.postsReduserName.item,
    name: state.postsReduserName.name,
    email: state.postsReduserName.email,
    nextScreen: state.postsReduserName.nextScreen,
    jobData: state.postsReduserName.jobData,
    imagesData: state.postsReduserName.imagesData
  });
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      fetchSrp: (payload) => {
        dispatch(fetchSrp(payload, ownProps));
      },
      postName: (payload) => {
        dispatch(postName(payload, ownProps));
      },
      postEmail: (payload) => {
        dispatch(postEmail(payload, ownProps));
      },
      save: (payload) => {
        dispatch(save(payload, ownProps));
      },
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Features));