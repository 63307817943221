import {
  POST_NAME,
  POST_EMAIL,
  NEXT_SCREEN,
  POST_SUB,
  POST_MESSAGE
  
} from "../pages/types";

export const postName = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action2 postName postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: POST_NAME,
    payload: postData,
  });
};

export const postEmail = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postEmail postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: POST_EMAIL,
    payload: postData,
  });
};

export const postSub = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postSub postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: POST_SUB,
    payload: postData,
  });
};


export const postMessage = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action postMessage postData >>>>>>>>");
  console.log(postData);
  dispatch({
    type: POST_MESSAGE,
    payload: postData,
  });
};



export const save = (postData, ownProps) => (dispatch, getState) => {
  console.log("in action save START >>>>>>>>>>>>>");
  console.log(postData);
  console.log("in action save END >>>>>>>>>>>>>");
  fetch("https://www.activesquares.com/save", {
    method: "POST",
    //mode: 'cors',
    headers: {
      "content-type": "application/json",
      'Accept': 'application/json'
    },
    body: JSON.stringify(postData),
  })
    .then(
     
      function (r) {
        console.log(r);
        r.text().then(

          function (data) {
            console.log("Status save >>>>>>");
            console.log(r.ok);

            if (r.ok) {
              console.log("Dispatching SAVE >>>>>>");
              
                dispatch({
                  type: NEXT_SCREEN,
                  payload: JSON.parse(data),
                });

                
            } else {
              
            }

            console.log("Body  >>>>>>");
            console.log(data);
          }
        );
      }
    )

    .then((obj) => console.log(obj));
};

